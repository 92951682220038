<template>
  <div>
    <!-- <loading v-if="isLoading" />
    <error v-else-if="isError" /> -->
    <b-card>
      <div class="custom-search d-flex justify-content-between mb-1">
        <!-- search input -->
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">{{ $t("g.searchLabel") }}</label>
            <b-form-input
              v-model="searchTerm"
              :placeholder="$t('g.searchHere')"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
        <div>
          <b-button
            :to="{ name: 'AddServicePurchaseRequest' }"
            variant="primary"
            >{{ $t("g.addServicePurchaseRequest") }}</b-button
          >
        </div>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        styleClass="vgt-table condensed"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-column" slot-scope="props">
          <!-- Column: Name -->
          <span
            v-if="props.column.label === 'request_number'"
            class="text-nowrap"
          >
            {{ $t("g.request_number") }}
          </span>
          <span
            v-else-if="props.column.label === 'lab_name'"
            class="text-nowrap"
          >
            {{ $t("g.lab_name") }}
          </span>
          <span
            v-else-if="props.column.label === 'provider_name'"
            class="text-nowrap"
          >
            {{ $t("g.provider_name") }}
          </span>
          <span
            v-else-if="props.column.label === 'dead_line_for_response'"
            class="text-nowrap"
          >
            {{ $t("g.dead_line_for_response") }}
          </span>
          <span
            v-else-if="props.column.label === 'type_of_request'"
            class="text-nowrap"
          >
            {{ $t("g.type_of_request") }}
          </span>

          <span v-else-if="props.column.label === 'action'" class="text-nowrap">
            {{ $t("g.action") }}
          </span>
          <span v-else>
            {{ props.column.label }}
          </span>
        </template>

        <!-- Slot: Table Row -->
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'ref_number_row'">
            <router-link
              :to="{ name: 'PreviewRequest', params: { id: props.row.id } }"
            >
              {{ props.row.ref_number }}
            </router-link>
          </span>
          <span v-else-if="props.column.field === 'last_response_date_row'">
            {{ format(props.row.last_response_date) }}
          </span>
          <span v-else-if="props.column.field === 'requester_name'">
            {{
              props.row.requester
                ? props.row.requester.first_name +
                  " " +
                  props.row.requester.last_name
                : null
            }}
          </span>
          <!-- Column: Action -->
          <span v-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item @click="deleteAlert(props.row.id)">
                  <feather-icon icon="Trash2Icon" class="mr-50" />
                  <span>{{ $t("g.delete") }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                {{ $t("g.pagelength") }}
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap">
                {{ $t("g.of") }} {{ props.total }}
                {{ $t("g.pageText") }}
              </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template> </vue-good-table
    ></b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BCard,
  BButton,
  BDropdownItem,
  BDropdown,
  VBTooltip,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import Ripple from "vue-ripple-directive";
import Loading from "@/views/components/logic/loading.vue";
import Error from "@/views/components/logic/error.vue";
import formatDate from "@/composables/format-date/format-date";

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BCard,
    BButton,
    Loading,
    Error,
    BDropdownItem,
    BDropdown,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      log: [],
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: this.$t("g.reference_number"),
          field: "ref_number_row",
        },
        {
          label: this.$t("g.supplier_name"),
          field: "supplier_name",
        },
        {
          label: this.$t("g.phone_number"),
          field: "phone_number",
        },
        {
          label: this.$t("g.email"),
          field: "email",
        },
        {
          label: this.$t("g.proofOfCompetence"),
          field: "proof",
        },
        {
          label: this.$t("g.last_response_date"),
          field: "last_response_date_row",
        },
        {
          label: this.$t("g.requester_name"),
          field: "requester_name",
        },
        {
          label: "action",
          field: "action",
        },
      ],
      rows: [],
      searchTerm: "",
      isLoading: true,
      isError: false,
      format: null,
    };
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  created() {
    this.format = formatDate;
    this.getBuyRequests();
  },
  methods: {
    getBuyRequests(){
      this.$http
      .get("admin/buyRequests")
      .then((res) => {
        this.rows = res.data.data;
        this.isLoading = false;
        this.isError = false;
      })
      .catch((err) => {
        console.log(err);
        this.isLoading = false;
        this.isError = true;
      });
    },
    deleteBuyRequest(id){
      this.$http
      .delete(`admin/buyRequests/${id}`)
      .then((res) => {
        console.log(res);
        console.log('test form yahya');
        this.getBuyRequests();
      })
      .catch((err) => {
        console.log(err);
        this.isLoading = false;
        this.isError = true;
      });
    },
    // Sweet Alert
    deleteAlert(id) {
      this.$swal({
        title: this.$t("g.areYouSure?"),
        text: this.$t("g.youWontBeAbleToRevertThis"), // You won't be able to revert this!
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("g.yesDeleteIt"), // "Yes, delete it!" ,
        cancelButtonText: this.$t("g.cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        this.deleteBuyRequest(id)
        if (result.value) {
          this.$swal({
            icon: "success",
            title: this.$t("g.deleted"), //"Deleted!"
            text: this.$t("g.yourFileHasBeenDeleted"), //"Your file has been deleted."
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
